import { useQuery } from "react-query";

import axios from "axios";

import { projectServiceEndpoint } from "api/project";

export const PROJECT_SHAPEFILE_FEATURES_QUERY_KEYS = {
  projectShapefileFeatures: "projectShapefileFeatures"
};

export type UseProjectShapefileFeaturesQueryKeysT =
  keyof typeof PROJECT_SHAPEFILE_FEATURES_QUERY_KEYS;

export type UseProjectShapefileFeaturesQueryParamsT = {
  id: string;
  isProjectLinkedShapefile: boolean;
  onSuccess?: (data) => void;
  onError?: (error) => void;
  queryKey?: UseProjectShapefileFeaturesQueryKeysT;
  selectedFeatureProperty?: string;
  sortDirection?: string;
};

export type UseProjectShapefileFeaturesQueryReturnT = {
  error;
  isError: boolean;
  isIdle: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  refetch: () => void;
  data;
};

const getShapefileFeatures = async ({ queryKey }) => {
  const [, id, isProjectLinkedShapefile, selectedFeatureProperty, sortDirection] =
    queryKey;
  if (isProjectLinkedShapefile) {
    return await axios.get(
      `${projectServiceEndpoint}/projectShapefile/${id}?includeGeom=true&selectedFeatureProperty=${encodeURIComponent(
        selectedFeatureProperty
      )}&order=${sortDirection}`
    );
  }

  return await axios.get(
    `${projectServiceEndpoint}/shapefile/${id}?includeGeom=true&selectedFeatureProperty=${encodeURIComponent(
      selectedFeatureProperty
    )}&order=${sortDirection}`
  );
};

export function useProjectShapefileFeaturesQuery({
  onSuccess,
  onError,
  id,
  isProjectLinkedShapefile,
  selectedFeatureProperty,
  sortDirection,
  queryKey = "projectShapefileFeatures"
}: UseProjectShapefileFeaturesQueryParamsT): UseProjectShapefileFeaturesQueryReturnT {
  const queryInfo = useQuery({
    queryKey: [
      queryKey,
      id,
      isProjectLinkedShapefile,
      selectedFeatureProperty,
      sortDirection
    ],
    queryFn: getShapefileFeatures,
    onError: (error) => {
      onError && onError(error);
    },
    onSuccess: (data) => {
      onSuccess && onSuccess(data);
    },
    enabled: !!id,
    refetchOnWindowFocus: false
  });

  return {
    error: queryInfo.error,
    isError: queryInfo.status === "error",
    isIdle: queryInfo.status === "idle",
    isLoading: queryInfo.status === "loading",
    isSuccess: queryInfo.status === "success",
    refetch: queryInfo.refetch,
    data: queryInfo.data?.data
  };
}
