import { ReactNode } from "react";

import { BetaFeatures } from "hooks/useBetaFeatures";

import { IGroupBy as GlobalGroupBy, INormalizeBySetting } from "models";
import { PdenSourceT } from "models/pdenDataSourceSetting";

import { ChartProductGroupT } from "../components/chart";
import { CalculationMethod } from "../components/chart/components/stacked-bar-options/StackedBarCalculation";
import { IAxisMinMax } from "../components/multiphase-chart/context/ChartSettingsContext";
import { IColorPalette } from "../components/user-settings/models";
import { ChartTypeLabels } from "../constants";
import { mBinSize } from "./binSize";
import { IScreenshotSettings } from "./screenshot";

export interface IBin {
  binSize: number;

  lessThan: number;
  greaterThan: number;

  minSize: number;
}

export interface IGroupBy {
  property: string; // groupBy category
  title: string; // groupBy field
  id: number; // groupBy field Id

  color: string;
  canBin: boolean;

  dataType: number;

  // position groupBy component
  place: string;
  align: string;
}

export interface IChart {
  key: string;

  traces: [];
  title: string;
  xAxisTitle: string;
  yAxisTitle: string;
  legendTitle: string;

  product: string;
  chartType: string;
  chartTypeId: number;

  bin: IBin;
  binVisible: boolean;

  groupBy: IGroupBy;
  groupByVisible: boolean;

  range: {
    x: [];
    y: [];
  };
  rangeVisible: boolean;

  // toolbar icons
  producing: boolean;
  showActiveWellsOnly: boolean;
  forecast: boolean;
  average: boolean;
  daily: boolean;
  palette: boolean;
  snapshot: boolean;
  legend: boolean;
  fullscreen: boolean;
  cutoffPercent: number;
  sum: boolean;
  survivorBias: boolean;
  useWeightedRatioAverage: boolean;
  // x and y axis scale
  log: {
    x: boolean;
    y: boolean;
  };

  // x and y axis groupBy params
  chartTypeParam: {
    x: IGroupBy;
    y: IGroupBy;
  };
  xAxisParamVisible: boolean;
  xAxisParamListVisible: boolean;
  yAxisParamVisible: boolean;
  yAxisParamListVisible: boolean;

  // loading indicator
  busy: boolean;
}

export type slopeLines = {
  halfSlope: Line;
  quarterSlope: Line;
  unitSlope: Line;
};

export type Line = {
  point1: Point;
  point2: Point;
};

export type Point = {
  x: number;
  y: number;
};

export interface AxisLockStatus {
  x: boolean;
  y: boolean;
}

export type ChartAxisType = "x" | "y";

export interface IChartType {
  label: ChartTypeLabels;
  id: number;
  lock?: { x: boolean; y: boolean };
  tooltip?: string;
}

export interface IProductType {
  key: string;
  label: string;
  id: number;
  lock?: number | null;
  available?: (hasFeature?: (feature: BetaFeatures) => boolean) => boolean;
  icon: ReactNode;
}

export interface IReferencePoint {
  // Values relative to pixel coordinates
  x: number;
  y: number;
  // Values relative to chart axis
  chartX?: number;
  chartY?: number;
}

export interface IReferenceLine {
  id: number;
  point1: IReferencePoint;
  point2: IReferencePoint;
  color?: string;
  thickness?: number;
}

export interface AverageSeriesStyle {
  color: string;
  thickness: number;
}

export interface SlopeLineStyle {
  color: string;
  thickness: number;
  length: number;
}

export type lineCoordinates = {
  p1: Point;
  p2: Point;
  chartP1: Point;
  chartP2: Point;
};

export enum SlopeTypes {
  Unit = "unit",
  Half = "half",
  Quarter = "quarter"
}

export interface IAllChartTypes {
  CrossPlot: IChartType;
  RateCum: IChartType;
  RateTime: IChartType;
  RateDate: IChartType;
  TotalRateDate: IChartType;
  CAGR: IChartType;
  BaseDeclineRate: IChartType;
  Probit: IChartType;
  BoxPlot: IChartType;
  Pie: IChartType;
  StackedBar: IChartType;
  Mosaic: IChartType;
  MaterialBalanceTime: IChartType;
  CumTime: IChartType;
  TotalRateCum: IChartType;
  TrendDate: IChartType;
  WellContribution: IChartType;
}

export interface IAllProductTypes {
  // primary
  Oil: IProductType;
  Gas: IProductType;
  Water: IProductType;
  TotalFluid: IProductType;
  BOE: IProductType;
  WaterInj: IProductType;
  GasInj: IProductType;
  SteamInj: IProductType;
  PolymerInj: IProductType;
  CO_2Inj: IProductType;
  AcidGasInj: IProductType;
  SolventInj: IProductType;
  TotalFluidInj: IProductType;
  TotalGasInj: IProductType;
  // ratio
  CGR: IProductType;
  GOR: IProductType;
  WGR: IProductType;
  WOR: IProductType;
  OnePlusWOR: IProductType;
  ISOR: IProductType;
  CSOR: IProductType;
  IWB: IProductType;
  CWB: IProductType;
  // percent
  OilCut: IProductType;
  WaterCut: IProductType;
  OnTime: IProductType;
  SalesGas: IProductType;
  Shrinkage: IProductType;
  C2: IProductType;
  C3: IProductType;
  C4: IProductType;
  C5: IProductType;
  SalesLiquids: IProductType;
  OilCondC5: IProductType;
  NGLMix: IProductType;
  NGL_234: IProductType;
  C2YieldsRaw: IProductType;
  C3YieldsRaw: IProductType;
  C4YieldsRaw: IProductType;
  C5YieldsRaw: IProductType;
  OilCondC5YieldsRaw: IProductType;
  LiquidsYieldsRaw: IProductType;
  NGLMixYieldsRaw: IProductType;
  NGL_234YieldsRaw: IProductType;
  C2YieldsSales: IProductType;
  C3YieldsSales: IProductType;
  C4YieldsSales: IProductType;
  C5YieldsSales: IProductType;
  LiquidsYieldsSales: IProductType;
  OilCondC5YieldsSales: IProductType;
  NGLMixYieldsSales: IProductType;
  NGL_234YieldsSales: IProductType;
  GasEnergy: IProductType;
  GasEnergyYieldsSales: IProductType;
  SalesBOE: IProductType;

  /*** midstream products **/
  GasInlet: IProductType;
  GasOutlet: IProductType;
  H2S: IProductType;
  PlantShrinkage: IProductType;
  Fuel: IProductType;
  Flared: IProductType;
  Vented: IProductType;
  Sulphur: IProductType;
  SulphurYieldsInlet: IProductType;
  SulphurYieldsOutlet: IProductType;
  C2YieldsInlet: IProductType;
  C3YieldsInlet: IProductType;
  C4YieldsInlet: IProductType;
  C5YieldsInlet: IProductType;
  OilCondC5YieldsInlet: IProductType;
  LiquidsYieldsInlet: IProductType;
  NGLMixYieldsInlet: IProductType;
  NGL_234YieldsInlet: IProductType;
  C2YieldsOutlet: IProductType;
  C3YieldsOutlet: IProductType;
  C4YieldsOutlet: IProductType;
  C5YieldsOutlet: IProductType;
  LiquidsYieldsOutlet: IProductType;
  OilCondC5YieldsOutlet: IProductType;
  NGL_234YieldsOutlet: IProductType;
  NGLMixYieldsOutlet: IProductType;
  GPWater: IProductType;
  GPWaterYieldsInlet: IProductType;
  GPWaterYieldsOutlet: IProductType;
  CO_2: IProductType;
  CO_2YieldsInlet: IProductType;
  CO_2YieldsOutlet: IProductType;
  InletUtilization: IProductType;
  CasingPressure: IProductType;
  TubingPressure: IProductType;
}

export type PressureChartSettings = {
  product: string;
  productivityIndex: boolean;
};

export const MidstreamProductGroups: ChartProductGroupT[] = ["Midstream"];

export type PdenTimeStepT = "month" | "day" | "hybrid";

export type SelectionMode = "polygon" | "none" | "rect" | "clear";
export type LegendPosition = "NE" | "NW" | "SE" | "SW";
export type LegendT = { visible: boolean; position: LegendPosition };

export interface IChartSettings {
  showP10: boolean;
  showP50: boolean;
  showP90: boolean;
  showUnitSlope: boolean;
  showHalfSlope: boolean;
  showQuarterSlope: boolean;
  stackedBarCalculation: CalculationMethod;
  pressureChartSettings: PressureChartSettings;
  selection?: SelectionMode;
  id: string;
  key?: string;
  chartType: ChartTypeLabels;
  chartTypeId: number;
  product: string;
  screenshot: boolean;
  groupByListVisible: boolean;
  colorPalette?: IColorPalette;
  useChartFocus?: boolean;
  groupBy?: GlobalGroupBy;
  showToolbar: boolean;
  showNormalize: boolean;
  enableZoom: boolean;
  forecast: boolean;
  average: boolean;
  typewells: boolean;
  averageSettings?: AverageSeriesStyle;
  p10Settings?: AverageSeriesStyle;
  p50Settings?: AverageSeriesStyle;
  p90Settings?: AverageSeriesStyle;
  unitSlopeStyle?: SlopeLineStyle;
  halfSlopeStyle?: SlopeLineStyle;
  quarterSlopeStyle?: SlopeLineStyle;
  producing: boolean;
  showOnlySelectedWells: boolean;
  normalizeBy: INormalizeBySetting;
  useNormalizeBy: boolean;
  showActiveWellOnly: true;
  legend: LegendT;
  timeStep: PdenTimeStepT;
  legendPosition: LegendPosition;
  palette: boolean;
  snapshot: boolean;
  source: PdenSourceT;
  fullscreen: boolean;
  sum: boolean;
  cutoff: number;
  shutInMonths: number;
  survivorBias: boolean;
  useWeightedRatioAverage: boolean;
  rangeVisible: boolean;
  xAxisParamColor: string;
  yAxisParamColor: string;
  xAxisParamVisible: boolean;
  log: {
    x: boolean;
    y: boolean;
  };
  yAxisParamVisible: boolean;
  xAxisParamListVisible: boolean;
  yAxisParamListVisible: boolean;
  busy: boolean;
  range: { x: []; y: [] };
  chartTypeParam: {
    x: {
      id: string;
      property: string;
      title: string;
      dataType: string;
      canBin: boolean;
      bin?: mBinSize;
      isForecastToggleOn?: boolean;
      forecastFolder?: string;
      product?: string;
      defaultUnit?: string;
    };
    y: {
      id: string;
      property: string;
      title: string;
      dataType: string;
      canBin: boolean;
      isForecastToggleOn?: boolean;
      forecastFolder?: string;
      product?: string;
      defaultUnit?: string;
    };
  };
  showWellCount?: boolean;
  lockUnits?: boolean;
  showLinesOfBestFit?: boolean;
  debugMode?: boolean;
  showScatter?: boolean;
  axisMinMax: IAxisMinMax;
  showHistogram?: boolean;
  addReferenceLine?: boolean;
  referenceLine?: {
    points: IReferencePoint[];
    lines: IReferenceLine[];
    useChartValues: boolean;
  };
  dotSize: number;
  dotOpacity: number;
  yAxisTitle?: string;
  movingAverageDays: number;
}

export interface IChartSeriesStyle {
  color: number;
  dashed: boolean;
  hexColor: string;
  itemColors: string[] | number[];
  thickness: number;
}

export interface IChartSeries {
  isForecast: boolean;
  isSecondaryYAxis: boolean;
  label: string;
  mode: string;
  style: IChartSeriesStyle;
  type: string;
  x: number[] | string[];
  y: number[] | string[];
  xAxisIndex: number;
  yAxisIndex: number;
  dates?: string[];
  uwiIndices?: number[];
  isComparisonForecast?: boolean;
  groupTitle?: string;
}

export interface EChartsSeries {
  id: string;
  name: string;
  group: string;
  type: string;
  animation: boolean;
  itemStyle: {
    color: string | unknown;
    borderColor?: string;
    opacity?: number;
  };
  blendMode: string;
  large: boolean;
  largeThreshold: number;
  line: {
    originalWidth: number;
    originalColor: string;
  };
  showSymbol: boolean;
  lineStyle: {
    color?: string;
    width?: number;
    type?: "dotted" | "dashed" | "solid";
  };
  data: unknown;
  isForecast?: boolean;
  dimensions?: string[];
  encode?: {
    x: string;
    y: string;
  };
  xAxisIndex?: number;
  yAxisIndex?: number;
  symbolSize?: number;
  symbol?: string;
  z?: number;
  emphasis?: unknown;
  radius?: string;
  center?: string[];
  stack?: string;
  areaStyle?: unknown;
  isComparisonForecast?: boolean;
}

export interface IChartScreenshotSettings extends IScreenshotSettings {
  axisFontSize?: number | undefined | null;
  titleFontSize?: number | undefined | null;
  axisLabelFontSize?: number | undefined | null;
  titleText?: string | undefined | null;
  showAxisTitle?: boolean | undefined | null;
  xAxisTitleText?: string | undefined | null;
  yAxisTitleText?: string | undefined | null;
  axisFontWeight?: number | undefined | null;
  showAxisLabels?: boolean | undefined | null;
  legendShowCounts?: boolean | undefined | null;
  legendOpacity?: number | undefined | null;
  legendType?: "bottom" | "inplot";
  legendPosition?: "NW" | "NE" | "SW" | "SE";
  fontFamily?: string | undefined | null;
  gridShowXLines?: boolean | undefined | null;
  gridShowXMinorLines?: boolean | undefined | null;
  gridShowYLines?: boolean | undefined | null;
  gridShowYMinorLines?: boolean | undefined | null;
  gridLeft?: number | undefined | null;
  gridTop?: number | undefined | null;
  gridRight?: number | undefined | null;
  gridBottom?: number | undefined | null;
  nameGapX?: number | undefined | null;
  nameGapY?: number | undefined | null;
  applyAbbreviations?: boolean | undefined | null;
}

export interface ValueLabel {
  value: string;
  label: string;
}

export interface ChartResponseAxis {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any[];
  defaultAxisType: string;
  interval: number;
  isSecondaryYAxis: boolean;
  labels: ValueLabel[];
  max: number;
  min: number;
  logMin?: number;
  title: string;
  yScaleToX: number;
}

export interface ChartLayout {
  xAxis: ChartResponseAxis;
  yAxis: ChartResponseAxis;
  yAxis2: ChartResponseAxis[];
  xAxis2: ChartResponseAxis[];
}

export interface ChartResponseModel {
  title?: string;
  layout: ChartLayout;
  series: IChartSeries[];
  uwis?: string[];
}
