import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";

import { IS_DEV_ENV } from "constants/app.constants";
import mapboxglx from "mapbox-gl";
import { RootState } from "store/rootReducer";

import { useEditableProjectLayerList } from "components/project/layers/hooks";
import { useProjectContext } from "components/project/projects/context";

import { useMapContext } from "../useMapContext";
import {
  debugMapboxSorting,
  getLayerListMergedWithMapbox,
  getMapLayersMatchedToLayerList
} from "./util";

/**  A reduced list layer, with only the properties we need to manage sorting */
export interface IOrderedLayer {
  name: string;
  order: number;
  shapefileId?: string;
  projectShapefileId?: string;
}

interface IUseLayerSorterForMapboxParams {
  mapbox: mapboxglx.Map;
}
export function useManageMapboxLayerSorting({ mapbox }: IUseLayerSorterForMapboxParams) {
  // const shapefileListQuery = useCheckedShapefileList();

  const {
    isMapInitialized,
    isStyleLoaded,
    isLoading: hasMapDependenciesLoading
  } = useMapContext();

  const { selectedProjectId } = useProjectContext();

  const layerList = useEditableProjectLayerList({
    projectId: selectedProjectId,
    filters: ["unselectedLayers"]
  });

  /**
   * Order needs to be in reverse as this is is how mapbox orders the layers, first layer on the layer list, is the last layer on the map
   */
  const layerListSorted = useMemo(() => {
    const tempArray = JSON.parse(JSON.stringify(layerList.data));
    const newList = tempArray.sort((a, b) => b.order - a.order);

    newList.forEach((layer, index) => {
      layer.order = index;
    });

    return newList;
  }, [layerList.data]);

  const currentShapefileFeatureProperty = useSelector(
    (state: RootState) => state.project.currentShapefileFeatureProperty
  );
  const currentShapefileIdForFeatureProperty = useSelector(
    (state: RootState) => state.project.currentShapefileIdForFeatureProperty
  );

  const isDebuggingMap =
    useSelector((state: RootState) => state.map.isDebuggingMap) && IS_DEV_ENV;

  /**
   * Manage sorting mapbox layers to match the the ordered layer list
   */
  useEffect(() => {
    if (
      !isMapInitialized ||
      !isStyleLoaded ||
      !mapbox?.getStyle?.()?.layers ||
      hasMapDependenciesLoading
    ) {
      return;
    }

    if (isDebuggingMap) {
      // eslint-disable-next-line no-console
      console.time("~~ Sorting time: ");
    }
    const mapboxLayers = mapbox.getStyle().layers;

    const unorderedMapLayers = getMapLayersMatchedToLayerList({
      layerList: layerListSorted,
      mapboxLayers: mapboxLayers
    });

    const orderedLayerList = getLayerListMergedWithMapbox({
      layerList: layerListSorted,
      mapboxLayers: unorderedMapLayers
    });

    const isMapboxMatchingOrderedLayerList =
      JSON.stringify(unorderedMapLayers) === JSON.stringify(orderedLayerList);

    if (!isMapboxMatchingOrderedLayerList) {
      let tempArray = [...unorderedMapLayers];
      orderedLayerList.forEach((orderedLayer) => {
        // Need to refetch the layers each time, because the mapbox layers reorder with each move.
        // Otherwise we won't be comparing accurately

        const mapboxLayer = tempArray.find(
          (mapboxLayer) =>
            mapboxLayer.order === orderedLayer.order &&
            mapboxLayer.name !== orderedLayer.name
        );

        // ordered layer must be in the mapbox layers to be moved
        if (mapboxLayer && mapbox.getLayer(orderedLayer?.name)) {
          // move it to the layer, where the order is desired
          mapbox.moveLayer(orderedLayer.name, mapboxLayer.name);
          tempArray = moveLayer(tempArray, orderedLayer.name, mapboxLayer.name);
        }
      });
    }

    if (isDebuggingMap) {
      // eslint-disable-next-line no-console
      console.log("~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~");
      // eslint-disable-next-line no-console
      console.timeEnd("~~ Sorting time: ");
      debugMapboxSorting(
        mapbox.getStyle().layers,
        mapbox.getStyle().sources,
        orderedLayerList
      );
    }
  }, [
    layerListSorted,
    isMapInitialized,
    isStyleLoaded,
    hasMapDependenciesLoading,
    currentShapefileFeatureProperty,
    currentShapefileIdForFeatureProperty
  ]);
}

function moveLayer(layers, targetLayerName, destinationLayerName) {
  const targetIndex = layers.findIndex((layer) => layer.name === targetLayerName);
  const destinationIndex = layers.findIndex(
    (layer) => layer.name === destinationLayerName
  );

  if (targetIndex === -1 || destinationIndex === -1) {
    return layers;
  }

  const [movedLayer] = layers.splice(targetIndex, 1);
  layers.splice(destinationIndex, 0, movedLayer);

  // Update order values
  layers.forEach((layer, index) => {
    layer.order = index;
  });

  return layers;
}
