import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";

import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { Alert, Button, Input, Select, Spin, Switch, TreeSelect } from "antd";
import colormap from "colormap";
import { IS_INTERNAL_ENV } from "constants/app.constants";
import { IPDB_CONTROL_WELLS_LAYER, IPDB_LAYER } from "constants/mapLayers.constants";
import _debounce from "lodash/debounce";
import { VectorSource } from "mapbox-gl";
import { RootState } from "store/rootReducer";
import styled from "styled-components";
import { getMaxDecimalPlaces } from "utils/getMaxDecimalPlaces";

import usePlayZoneFields from "hooks/usePlayZoneFields";

import { getGroupedPlayZonesByPeriod, getIpdb } from "api/map";

import { LegendItemModel } from "models/LegendItem";

import { BaseIconToggle, BaseMenu, BaseTooltip } from "components/base";
import { IconSpinner, IpdbIcon } from "components/icons";

import { IpdbField } from "../../../api/ipdp";
import useUserModules from "../../../hooks/useUserModules";
import { getIpdbBin } from "../../../utils/getIpdbBin";
import getGeoMapLayers from "../../geo-map/hooks/getGeoMapLayers";
import { MapLayer } from "../../geo-map/models/mapLayer";
import { useIpdbContext, useIpdbDispatch } from "../contexts/IpdbContext";
import { useMapContext } from "../hooks/useMapContext";
import { useMapDispatch } from "../hooks/useMapDispatch";
import { calculateBinValues } from "../utils/calculateBinValues";
import { sortTreeData } from "../utils/sortTreeData";

const mapServiceEndpoint = process.env.REACT_APP_MAP_SERVICE;
const { Option } = Select;

const ipdbColorMaps = [
  "jet",
  "portland",
  "spring",
  "autumn",
  "viridis",
  "inferno",
  "rainbow",
  "bluered",
  "RdBu"
];
export const TWO_D_MODEL = "mcdan-two-d";
export const THREE_D_MODEL = "mcdan-three-d";

export default function Ipdb() {
  const { has3dGeoModel } = useUserModules();
  const mapExtent = useSelector((state: RootState) => state.map.mapExtent);
  // TODO: turn this back on when net model is ready for EVA
  // const user = useSelector((state: RootState) => state.auth.user);
  // const { organization } = user;
  const [selectedIpdbFieldData, setSelectedIpdbFieldData] = useState<{
    name: string;
    minMax: { min: number; max: number };
    ipdbBin;
  }>({
    name: "",
    minMax: null,
    ipdbBin: null
  });
  const prevMinMaxRef = useRef(selectedIpdbFieldData.minMax);

  const { mapbox } = useMapContext();
  const [modelSource, setModelSource] = useState("");
  const [showControlWells, setShowControlWells] = useState(false);
  const [useNativeSource, setUseNativeSource] = useState(false);
  const [binErrorMsg, setBinErrorMsg] = useState("");
  const mapDispatch = useMapDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedIpdbZones, setSelectedIpdbZones] = useState<string[]>([]);
  const [ipdbZoneInCalculation, setIpdbZoneInCalculation] = useState<string[]>([]);
  const [periodKeys, setPeriodKeys] = useState([]);
  const { showIpdb } = useIpdbContext();
  const [treeData, setTreeData] = useState([]);
  const [ipdbColorPaletteName, setIpdbColorPaletteName] = useState("portland");
  const [reverseColor, setReverseColor] = useState(false);

  const [isBinUsingDefaultValues, setIsBinUsingDefaultValues] = useState(true);

  const { data: zoneFields, refetch: refetchPlayZoneFields } = usePlayZoneFields(
    modelSource,
    selectedIpdbZones
  );

  const [allFieldsDataForSelectedZones, setAllFieldsDataForSelectedZones] =
    useState<IpdbField[]>(zoneFields);

  useEffect(() => {
    setAllFieldsDataForSelectedZones(zoneFields);
  }, [zoneFields]);

  useEffect(() => {
    prevMinMaxRef.current = selectedIpdbFieldData.minMax;
  }, [selectedIpdbFieldData.minMax]);

  useEffect(() => {
    if (binErrorMsg) {
      // Clear the IPDB colors on the map when there's an error.
      mapbox.setPaintProperty(IPDB_LAYER, "fill-color", "transparent");
      ipdbDispatch({
        payload: {
          legendItems: [],
          legendTitle: ""
        },
        type: "update"
      });
    }
  }, [binErrorMsg]);

  const mcdanielGroup = [
    {
      label: "McDaniel Research",
      options: [
        // TODO: turn this back on when net model is ready for EVA
        // ...(IS_INTERNAL_ENV || organization?.id.toLowerCase() == MCDAN_ORG_ID
        ...(IS_INTERNAL_ENV ? [{ label: "Net Model", value: TWO_D_MODEL }] : []),
        { label: "Gross Model", value: THREE_D_MODEL }
      ]
    }
  ];
  const [modelSources, setModelSources] = useState(has3dGeoModel ? mcdanielGroup : []);
  const { data: mapLayers } = getGeoMapLayers();
  useEffect(() => {
    const sources = [];
    if (has3dGeoModel) {
      sources.push(...mcdanielGroup);
    }

    if (mapLayers?.length > 0) {
      const orgGroups = {
        label: "Organization",
        options: []
      };

      for (const item of mapLayers) {
        if (orgGroups.options.findIndex((o) => o.value === item.group) === -1) {
          orgGroups.options.push({ label: item.group, value: item.group });
        }
      }
      sources.push(orgGroups);
    }

    setModelSources(sources);
    if (sources?.length > 0) {
      setModelSource(sources[0].options[0].value);
    }
  }, [mapLayers]);

  const convertToTreeData = (data) => {
    const sortedTree = sortTreeData(data);
    return sortedTree.map((period) => ({
      title: period.period,
      value: `period_${period.period}`,
      key: `period_${period.period}`,
      type: `period`,
      checkable: false,
      children: period.plays.map((play) => ({
        title: play.play,
        value: `play_${play.play}`,
        key: `play_${play.play}`,
        type: `play`,
        children: play.zones.map((zone) => ({
          title: zone,
          value: zone,
          key: zone,
          type: `zone`
        }))
      }))
    }));
  };

  useEffect(() => {
    if (
      !mapExtent ||
      mapExtent.type !== "Polygon" ||
      mapExtent.coordinates.length === 0 ||
      !showIpdb
    ) {
      return;
    }

    if (modelSource === TWO_D_MODEL || modelSource === THREE_D_MODEL) {
      getGroupedPlayZonesByPeriod().then((response) => {
        const treeData = convertToTreeData(response.data) ?? [];
        setPeriodKeys(response.data.map((p) => `period_${p.period}`));
        setTreeData(treeData);
      });
    } else if (mapLayers?.length > 0) {
      const layers = mapLayers.filter((layer) => layer.group === modelSource);
      const treeData = [
        {
          title: modelSource,
          value: `${modelSource}`,
          key: `play_${modelSource}`,
          type: `play`,
          checkable: false,
          children: layers.map((layer: MapLayer) => ({
            title: layer.name,
            value: layer.name,
            key: `play_${layer.name}`,
            type: `play`
          }))
        }
      ];
      setTreeData(treeData);
    }
  }, [mapExtent, showIpdb, modelSource]);

  const getIpdbFieldDataForSelectedZonesAndField = (
    allFieldsDataForSelectedZones: IpdbField[],
    selectedFieldName: string
  ) => {
    return allFieldsDataForSelectedZones?.find((f) => f.name === selectedFieldName);
  };

  function parseIpdbBinValues(
    ipdbData: { ipdbBin: { lessThan: string; binSize: string; greaterThan: string } },
    defaultIpdbFieldData: { min: number; max: number; bin: number },
    isBinUsingDefaultValues: boolean
  ) {
    // This parseFloat is so that the values are converted to numbers.
    // Note: Trailing zeros are removed as part of the conversion.
    let parsedIpdbBinValues = {
      lessThan: parseFloat(ipdbData?.ipdbBin?.lessThan),
      binSize: parseFloat(ipdbData?.ipdbBin?.binSize),
      greaterThan: parseFloat(ipdbData?.ipdbBin?.greaterThan)
    };

    const isBinLessThanValueANumber = !isNaN(parsedIpdbBinValues?.lessThan);
    const isBinSizeValueANumber = !isNaN(parsedIpdbBinValues?.binSize);
    const isBinGreaterThanValueANumber = !isNaN(parsedIpdbBinValues?.greaterThan);

    // Note: The reason for NaN is to keep it consistent with how the parseFloat function works.
    let lessThan = NaN;
    if (isBinLessThanValueANumber) {
      lessThan = parsedIpdbBinValues?.lessThan;
    } else if (isBinUsingDefaultValues) {
      lessThan = defaultIpdbFieldData?.min;
    }

    let binSize = NaN;
    if (isBinSizeValueANumber) {
      binSize = parsedIpdbBinValues?.binSize;
    } else if (isBinUsingDefaultValues) {
      binSize = defaultIpdbFieldData?.bin;
    }

    let greaterThan = NaN;
    if (isBinGreaterThanValueANumber) {
      greaterThan = parsedIpdbBinValues?.greaterThan;
    } else if (isBinUsingDefaultValues) {
      greaterThan = defaultIpdbFieldData?.max;
    }

    // Use the parsed values unless the user is using the default values.
    parsedIpdbBinValues = {
      lessThan,
      binSize,
      greaterThan
    };

    return parsedIpdbBinValues;
  }

  const ipdbDispatch = useIpdbDispatch();
  const onBinSettingChange = useCallback(
    async (ipdbData) => {
      let count = 0;
      while (mapbox && !mapbox.isStyleLoaded() && count < 100) {
        setIsLoading(true);
        mapDispatch({
          payload: {
            isLoading: true
          }
        });
        await new Promise((r) => setTimeout(r, 1000));
        count++;
      }
      setIsLoading(false);
      mapDispatch({
        payload: {
          isLoading: false
        }
      });

      if (!mapbox.isStyleLoaded()) {
        return;
      }

      // Assumption: If min and max from the dataset are the same, there is an issue with the data or there is no data.
      const hasNoIpdbFieldMinMaxData =
        (!ipdbData.minMax ||
          ipdbData.minMax.max == ipdbData.minMax.min ||
          isNaN(ipdbData.minMax.min) ||
          isNaN(ipdbData.minMax.max)) &&
        !!ipdbData.name &&
        prevMinMaxRef.current === ipdbData.minMax;

      if (hasNoIpdbFieldMinMaxData) {
        setBinErrorMsg("Selected field/zone combination has no data.");
        setSelectedIpdbFieldData((prevData) => ({
          ...prevData,
          ipdbBin: null
        }));
        return;
      } else if (prevMinMaxRef.current !== ipdbData.minMax) {
        return;
      }

      let parsedIpdbBinValues = parseIpdbBinValues(
        ipdbData,
        ipdbData.minMax,
        isBinUsingDefaultValues
      );

      parsedIpdbBinValues = {
        ...parsedIpdbBinValues,
        lessThan:
          ipdbData.ipdbBin.lessThan === ""
            ? ipdbData.ipdbBin.lessThan
            : parsedIpdbBinValues.lessThan,
        greaterThan:
          ipdbData.ipdbBin.greaterThan === ""
            ? ipdbData.ipdbBin.greaterThan
            : parsedIpdbBinValues.greaterThan
      };

      const binValues = calculateBinValues(parsedIpdbBinValues, ipdbData.minMax);

      if (!binValues && ipdbData.name) {
        setBinErrorMsg("Unable to generate IPDB colours due to binning.");
        return;
      } else {
        setBinErrorMsg("");
      }

      if (!binValues) {
        return;
      }

      // This update is to convert the bin values to numbers and remove the trailing zeroes.
      setSelectedIpdbFieldData((prevSelectedIpdbField) => ({
        ...prevSelectedIpdbField,
        ipdbBin: parsedIpdbBinValues
      }));

      const { bin, min, max, steps, numOfColors } = binValues;
      const binColorPairs: { value: number; color: string }[] = [];

      try {
        const colors = colormap({
          colormap: ipdbColorPaletteName,
          nshades: numOfColors,
          format: "hex",
          alpha: 1
        });

        if (reverseColor) {
          colors.reverse(); // Reverses in place.
        }

        for (let i = 0; i < numOfColors; i++) {
          binColorPairs.push({ value: min + steps * i, color: colors[i] });
        }

        const legendItems = [];
        const decimalPlaces = getMaxDecimalPlaces([min, max, bin]);

        binColorPairs.forEach((pair, idx) => {
          const li = new LegendItemModel(pair.value.toFixed(1));
          li.text = "";
          // This is to ensure the binValue doesn't exceed the max value if the bin size is not a perfect divisor of the max value.
          const binValue =
            pair.value > max
              ? max.toFixed(decimalPlaces)
              : pair.value.toFixed(decimalPlaces);
          if (idx == 0) {
            if (ipdbData.ipdbBin.lessThan === "" || isNaN(ipdbData.ipdbBin.lessThan)) {
              li.text = `${ipdbData.minMax.min.toFixed(decimalPlaces)} - ${binValue}`;
            } else {
              li.text = "< " + binValue;
            }
          } else if (
            idx === numOfColors - 1 &&
            !isNaN(ipdbData.ipdbBin.greaterThan) &&
            ipdbData.ipdbBin.greaterThan !== ""
          ) {
            li.text = "≥ " + binValue;
          } else {
            const previousBinValue = binColorPairs[idx - 1].value.toFixed(decimalPlaces);
            li.text = `${previousBinValue} - ${binValue}`;
          }
          li.title = li.text;
          li.value = binValue;
          li.color = pair.color;
          legendItems.push(li);
        });
        ipdbDispatch({
          payload: {
            legendItems
          },
          type: "update"
        });

        const newPaint = {
          property: "val",
          stops: binColorPairs.map((pair) => [pair.value, pair.color])
        };
        mapbox.setPaintProperty(IPDB_LAYER, "fill-color", newPaint);

        const defaultIpdbFieldData = getIpdbFieldDataForSelectedZonesAndField(
          allFieldsDataForSelectedZones,
          ipdbData.name
        );

        ipdbDispatch({
          payload: {
            legendTitle: `${(ipdbZoneInCalculation ?? []).map((z) => ` ${z}`)} - ${
              defaultIpdbFieldData?.displayName != null
                ? defaultIpdbFieldData?.displayName
                : ipdbData.name
            } ${defaultIpdbFieldData?.unit != null ? defaultIpdbFieldData?.unit : ""}`
          },
          type: "update"
        });
      } catch (err) {
        let errorMessage = err?.message ?? "An error occurred.";
        // Change the error message to be more user-friendly.
        const match = errorMessage.match(/requires nshades to be at least size (\d+)/);
        if (match) {
          const size = match[1];
          errorMessage = `Selected colour palette requires at least ${size} bins. Please update your bin settings.`;
        }
        setBinErrorMsg(errorMessage);
      }
    },
    [
      selectedIpdbFieldData.name,
      selectedIpdbFieldData.minMax,
      ipdbColorPaletteName,
      ipdbDispatch,
      mapDispatch,
      mapbox,
      reverseColor
    ]
  );

  const binSettingDebounce = useMemo(
    () => _debounce((ipdbData) => onBinSettingChange(ipdbData), 1000),
    [onBinSettingChange]
  );

  useEffect(() => {
    if (!mapbox || !selectedIpdbFieldData.name) {
      return;
    }

    binSettingDebounce(selectedIpdbFieldData);
  }, [binSettingDebounce, selectedIpdbFieldData.minMax]);

  async function waitForStyleToLoad() {
    let count = 0;
    while (mapbox && !mapbox.isStyleLoaded() && count < 20) {
      await new Promise((r) => setTimeout(r, 500));
      count++;
    }
  }

  const selectedZoneDebounce = useMemo(
    () => _debounce((zones) => setIpdbZoneInCalculation(zones), 1400),
    [setIpdbZoneInCalculation]
  );

  useEffect(() => {
    const reasonableBinSize = (binSize: number): number => {
      if (binSize <= 0) {
        binSize = 1;
      }
      const orderOfMagnitude = Math.pow(10, Math.floor(Math.log10(binSize)));

      const significantFigures = [1, 2, 2.5, 5, 7.5, 10];

      const scaledBinSize = binSize / orderOfMagnitude;
      for (let i = significantFigures.length - 1; i >= 0; i--) {
        if (scaledBinSize >= significantFigures[i]) {
          return significantFigures[i] * orderOfMagnitude;
        }
      }

      return orderOfMagnitude;
    };
    selectedZoneDebounce(selectedIpdbZones);
    if (modelSource === TWO_D_MODEL || modelSource === THREE_D_MODEL) {
      refetchPlayZoneFields();
    } else if (mapLayers?.length > 0) {
      const layers = mapLayers.filter((layer) => layer.group === modelSource);
      setAllFieldsDataForSelectedZones([]);
      if (layers.length == 0) {
        return;
      }
      const matchedLayers = layers.filter((f) => selectedIpdbZones.indexOf(f.name) >= 0);
      let allMinMaxValues = {};
      for (const layer of matchedLayers) {
        allMinMaxValues = { ...allMinMaxValues, ...layer.mapMinMaxValues };
      }
      const firstLayer = matchedLayers.length == 0 ? layers[0] : matchedLayers[0];
      if (Object.keys(allMinMaxValues).length == 0) {
        allMinMaxValues = firstLayer?.mapMinMaxValues ?? {};
      }
      const fields = Object.keys(allMinMaxValues)
        .sort((a, b) => a.localeCompare(b))
        .map((key) => {
          let minmax = allMinMaxValues[key];
          if (Math.abs(minmax.max - minmax.min) < 1e-10) {
            minmax = { min: minmax.min - 0.5, max: minmax.max + 0.5 };
          }
          const layer = firstLayer.maps.find(
            (l) => (l.Raster ?? l.Calculation)?.name === key
          );

          const layerUnit = (layer?.Raster ?? layer?.Calculation)?.unit ?? "";
          let binSize = 1.0;
          if (minmax.max - minmax.min > 0) {
            binSize = (minmax.max - minmax.min) / 7;
            binSize = reasonableBinSize(binSize);
          }
          const { bin } = calculateBinValues(
            { binSize, lessThan: minmax.min, greaterThan: minmax.max },
            minmax
          );

          return {
            name: key,
            displayName: key,
            min: Math.floor(minmax.min),
            max: Math.ceil(minmax.max),
            unit: layerUnit,
            bin: bin
          } as IpdbField;
        });
      setAllFieldsDataForSelectedZones(fields);
    }
  }, [refetchPlayZoneFields, selectedIpdbZones, selectedZoneDebounce, modelSource]);

  useEffect(() => {
    // Updates the bin values and the map and applies default values when the field changes.
    // Or when the zone changes and the user has not changed the default bin values.
    const data = getIpdbFieldDataForSelectedZonesAndField(
      allFieldsDataForSelectedZones,
      selectedIpdbFieldData.name
    );
    if (
      data?.min != null &&
      data?.max != null &&
      data?.bin != null &&
      isBinUsingDefaultValues
    ) {
      setSelectedIpdbFieldData((prevSelectedIpdbField) => {
        const updatedData = {
          ...prevSelectedIpdbField,
          ipdbBin: {
            lessThan: data.min,
            binSize: data.bin,
            greaterThan: data.max
          }
        };
        binSettingDebounce(updatedData);
        return updatedData;
      });
    }
  }, [
    binSettingDebounce,
    selectedIpdbFieldData.name,
    selectedIpdbZones,
    isBinUsingDefaultValues,
    allFieldsDataForSelectedZones
  ]);

  useEffect(() => {
    async function updateIpdb() {
      if (!mapbox) {
        return;
      }
      await waitForStyleToLoad();
      mapbox.setLayoutProperty(IPDB_LAYER, "visibility", showIpdb ? "visible" : "none");
      mapbox.setLayoutProperty(
        IPDB_CONTROL_WELLS_LAYER,
        "visibility",
        showIpdb && showControlWells ? "visible" : "none"
      );
      if (!showIpdb) {
        return;
      }

      if (!selectedIpdbFieldData.name) {
        ipdbDispatch({
          payload: {
            legendItems: [],
            legendTitle: ""
          },
          type: "update"
        });
        return;
      }

      if (!ipdbZoneInCalculation) return;
      let response = null;
      try {
        response = await getIpdb(
          modelSource,
          ipdbZoneInCalculation,
          selectedIpdbFieldData.name
        );

        if (response.status !== 200) {
          return;
        }
      } catch (err) {
        setBinErrorMsg("Error fetching IPDB data.");
        return;
      }

      const { minVal: min, maxVal: max } = response.data;
      const minMaxData = { min, max };

      const hasNoIpdbFieldMinMaxData =
        (!minMaxData || minMaxData.max == minMaxData.min) && !!selectedIpdbFieldData.name;

      if (hasNoIpdbFieldMinMaxData) {
        setSelectedIpdbFieldData((prevSelectedIpdbField) => ({
          ...prevSelectedIpdbField,
          minMax: minMaxData
        }));
        return;
      }

      const defaultIpdbFieldData = getIpdbFieldDataForSelectedZonesAndField(
        allFieldsDataForSelectedZones,
        selectedIpdbFieldData.name
      );

      let parsedIpdbBinValues = parseIpdbBinValues(
        selectedIpdbFieldData,
        defaultIpdbFieldData,
        isBinUsingDefaultValues
      );

      let numOfColors: number;

      const hasNoDefaultIpdbValuesInDatabase =
        defaultIpdbFieldData &&
        (defaultIpdbFieldData.min == null ||
          defaultIpdbFieldData.max == null ||
          defaultIpdbFieldData.bin == null);

      if (isBinUsingDefaultValues && hasNoDefaultIpdbValuesInDatabase) {
        // Calculate the default using the data.
        // Note: numOfColors is hardcoded because the colormap library has a restriction for min number of colors depending on the palette chosen.
        numOfColors = 9;
        parsedIpdbBinValues = getIpdbBin(
          minMaxData.min,
          numOfColors,
          minMaxData.max,
          true
        );

        setSelectedIpdbFieldData((prevSelectedIpdbField) => ({
          ...prevSelectedIpdbField,
          minMax: { min: Math.floor(minMaxData.min), max: Math.ceil(minMaxData.max) },
          ipdbBin: parsedIpdbBinValues
        }));
      } else {
        setSelectedIpdbFieldData((prevSelectedIpdbField) => ({
          ...prevSelectedIpdbField,
          minMax: { min: Math.floor(minMaxData.min), max: Math.ceil(minMaxData.max) }
        }));
      }

      const binValues = calculateBinValues(
        parsedIpdbBinValues,
        selectedIpdbFieldData.minMax
      );

      if (!binValues && selectedIpdbFieldData) {
        setBinErrorMsg("Unable to generate IPDB colours due to binning.");
        return;
      } else {
        setBinErrorMsg("");
      }

      numOfColors = binValues.numOfColors;

      try {
        const colors = colormap({
          colormap: ipdbColorPaletteName,
          nshades: numOfColors,
          format: "hex",
          alpha: 1
        });

        if (reverseColor) {
          colors.reverse(); // Reverses in place.
        }

        setBinErrorMsg("");

        const stops = [];
        for (let i = 0; i < numOfColors; i++) {
          stops.push([
            parsedIpdbBinValues.lessThan + parsedIpdbBinValues.binSize * i,
            colors[i]
          ]);
        }

        const zone = btoa(JSON.stringify(ipdbZoneInCalculation)).replace("=", "");
        const field = btoa(selectedIpdbFieldData.name).replace("=", "");
        const url = `${mapServiceEndpoint}/ipdb/${field}/${encodeURIComponent(
          modelSource
        )}/${zone}/{z}/{x}/{y}.mvt?native=${useNativeSource}`;

        const newStyle = mapbox.getStyle();
        (newStyle.sources["eva.ipdbsource"] as VectorSource).tiles = [url];
        const newPaint = {
          property: "val",
          stops
        };
        if (
          showControlWells &&
          showIpdb &&
          (modelSource === THREE_D_MODEL || modelSource === TWO_D_MODEL)
        ) {
          (newStyle.sources["eva.control-wells"] as VectorSource).tiles = [
            `${mapServiceEndpoint}/ipdb/control/${encodeURIComponent(
              modelSource
            )}/${zone}/{z}/{x}/{y}`
          ];
        } else {
          const emptyZone = btoa(JSON.stringify([])).replace("=", "");
          (newStyle.sources["eva.control-wells"] as VectorSource).tiles = [
            `${mapServiceEndpoint}/ipdb/control/${encodeURIComponent(
              modelSource
            )}/${emptyZone}/{z}/{x}/{y}`
          ];
        }

        mapbox.setStyle(newStyle);
        mapbox.setPaintProperty(IPDB_LAYER, "fill-color", newPaint);

        ipdbDispatch({
          payload: {
            legendTitle: `${(ipdbZoneInCalculation ?? []).map((z) => ` ${z}`)} - ${
              defaultIpdbFieldData?.displayName != null
                ? defaultIpdbFieldData?.displayName
                : selectedIpdbFieldData.name
            } ${defaultIpdbFieldData?.unit != null ? defaultIpdbFieldData?.unit : ""}`
          },
          type: "update"
        });
      } catch (err) {
        let errorMessage = err?.message ?? "An error occurred.";
        // Change the error message to be more user-friendly.
        const match = errorMessage.match(/requires nshades to be at least size (\d+)/);
        if (match) {
          const size = match[1];
          errorMessage = `Selected colour palette requires at least ${size} bins. Please update your bin settings.`;
        }
        setBinErrorMsg(errorMessage);
      }
    }

    updateIpdb();
  }, [
    showIpdb,
    showControlWells,
    ipdbZoneInCalculation,
    selectedIpdbFieldData.name,
    allFieldsDataForSelectedZones,
    ipdbColorPaletteName,
    useNativeSource,
    modelSource,
    mapbox,
    ipdbDispatch
  ]);

  if (!modelSources?.length || !mapbox) {
    return <></>;
  }

  return (
    <>
      <BaseMenu
        trigger={
          <BaseTooltip text="3D Geo Model Options">
            <BaseIconToggle
              data-testid="2d-geo-options-toggle"
              squareIcon
              className="item">
              <IpdbIcon />
            </BaseIconToggle>
          </BaseTooltip>
        }>
        {() => {
          return (
            <div className="ipdb-options" data-testid="ipdb-options-modal">
              {isLoading && (
                <LoadingContainer data-testid="ipdb-loading-spinner">
                  <Spin indicator={<IconSpinner />} />
                </LoadingContainer>
              )}
              <IpdbOptionItem className="ipdb-option-item">
                <label>Show Geo Model</label>
                <Switch
                  data-testid="show-geo-model-toggle"
                  checked={showIpdb}
                  onChange={(val) =>
                    ipdbDispatch({
                      payload: { showIpdb: val },
                      type: "update"
                    })
                  }></Switch>
              </IpdbOptionItem>
              <IpdbOptionItem className="ipdb-option-item">
                <label>Geo Model</label>
                <Select
                  data-testid="geo-model-select"
                  value={modelSource}
                  options={modelSources}
                  onChange={(e) => {
                    setModelSource(e);
                    setSelectedIpdbZones([]);
                    setSelectedIpdbFieldData((prevSelectedIpdbField) => ({
                      ...prevSelectedIpdbField,
                      name: ""
                    }));
                  }}
                />
              </IpdbOptionItem>

              <IpdbOptionItem className="ipdb-option-item">
                <label data-testid="zone-title">Zone</label>
                <TreeSelect
                  showSearch
                  data-testid="geo-zone-select"
                  suffixIcon={<KeyboardArrowDownIcon fontSize="large" />}
                  switcherIcon={
                    <SwitcherIcon role="img">
                      <KeyboardArrowDownIcon fontSize="large" />
                    </SwitcherIcon>
                  }
                  value={selectedIpdbZones}
                  dropdownStyle={{ overflow: "auto" }}
                  placeholder="Please select"
                  allowClear
                  multiple
                  maxTagCount={10}
                  onChange={setSelectedIpdbZones}
                  treeDefaultExpandedKeys={periodKeys}
                  treeCheckable={true}
                  treeData={treeData}
                />
              </IpdbOptionItem>

              <IpdbOptionItem className="ipdb-option-item">
                <label>Field</label>
                <Select
                  value={selectedIpdbFieldData.name}
                  popupClassName="modal-select ipdb-field-select"
                  onChange={(fieldValue: string) => {
                    // When the field is changed, we want to overwrite the user's bin values with the defaults.
                    setIsBinUsingDefaultValues(true);
                    setSelectedIpdbFieldData((prevSelectedIpdbField) => ({
                      ...prevSelectedIpdbField,
                      name: fieldValue
                    }));
                  }}>
                  {allFieldsDataForSelectedZones?.length
                    ? allFieldsDataForSelectedZones.map((field) => {
                        return (
                          <Option key={field.name} value={field.name}>
                            {field.displayName}
                          </Option>
                        );
                      })
                    : null}
                </Select>
              </IpdbOptionItem>

              <IpdbOptionItem className="ipdb-option-item">
                <label>Unit</label>
                <StyledLabel>
                  {getIpdbFieldDataForSelectedZonesAndField(
                    allFieldsDataForSelectedZones,
                    selectedIpdbFieldData.name
                  )?.unit.replace(/[()]/g, "") ?? ""}
                </StyledLabel>
              </IpdbOptionItem>
              <IpdbOptionItem className="ipdb-option-item">
                <label>Bin</label>
                <BinContainer>
                  <BinItem>
                    <BinInputLabel>Less Than</BinInputLabel>
                    <Input
                      type="number"
                      data-testid="less-than-input"
                      onChange={(evt) => {
                        // Cast to unknown first so that the user can input any decimal value, to bypass the TypeScript compiler's type checking.
                        // Problem was with the decimal place and values such as 1.0, parsing it directly as a float/number will be 1.
                        // So the user could never enter a value like 1.01.
                        /* !!! NOTE: Even though the input value type is number and also cast as a number, when you do "typeof inputValue" it is a string.
                                     This is not right, but it allows us to enter our values properly, but we still need to convert it later when using it (ex. parseFloat). !!!
                        */
                        const inputValue = evt.target.value as unknown as number;
                        setIsBinUsingDefaultValues(false);
                        setSelectedIpdbFieldData((prevIpdbData) => {
                          return {
                            ...prevIpdbData,
                            ipdbBin: {
                              ...prevIpdbData.ipdbBin,
                              lessThan: inputValue
                            }
                          };
                        });
                      }}
                      value={selectedIpdbFieldData.ipdbBin?.lessThan}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          onBinSettingChange(selectedIpdbFieldData);
                        }
                      }}
                    />
                  </BinItem>
                  <BinItem>
                    <BinInputLabel>Bin Size</BinInputLabel>
                    <Input
                      type="number"
                      min={0}
                      onChange={(evt) => {
                        const inputValue = evt.target.value as unknown as number;
                        setIsBinUsingDefaultValues(false);
                        setSelectedIpdbFieldData((prevIpdbData) => {
                          return {
                            ...prevIpdbData,
                            ipdbBin: {
                              ...prevIpdbData.ipdbBin,
                              binSize: inputValue
                            }
                          };
                        });
                      }}
                      value={selectedIpdbFieldData.ipdbBin?.binSize}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          onBinSettingChange(selectedIpdbFieldData);
                        }
                      }}
                    />
                  </BinItem>
                  <BinItem>
                    <BinInputLabel>Greater Than</BinInputLabel>
                    <Input
                      type="number"
                      value={selectedIpdbFieldData.ipdbBin?.greaterThan}
                      onChange={(evt) => {
                        const inputValue = evt.target.value as unknown as number;
                        setIsBinUsingDefaultValues(false);

                        setSelectedIpdbFieldData((prevIpdbData) => {
                          return {
                            ...prevIpdbData,
                            ipdbBin: {
                              ...prevIpdbData.ipdbBin,
                              greaterThan: inputValue
                            }
                          };
                        });
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          onBinSettingChange(selectedIpdbFieldData);
                        }
                      }}
                    />
                  </BinItem>
                </BinContainer>
              </IpdbOptionItem>
              <IpdbOptionItem className="ipdb-option-item">
                <label>Show Control Wells</label>
                <Switch
                  checked={
                    showControlWells &&
                    showIpdb &&
                    (modelSource === THREE_D_MODEL || modelSource === TWO_D_MODEL)
                  }
                  disabled={
                    !showIpdb ||
                    (modelSource !== THREE_D_MODEL && modelSource !== TWO_D_MODEL)
                  }
                  onChange={setShowControlWells}></Switch>
              </IpdbOptionItem>
              {modelSource !== THREE_D_MODEL && modelSource !== TWO_D_MODEL && (
                <IpdbOptionItem className="ipdb-option-item">
                  <label>Use Original Source</label>
                  <Switch
                    checked={useNativeSource && showIpdb}
                    disabled={!showIpdb}
                    onChange={setUseNativeSource}></Switch>
                </IpdbOptionItem>
              )}
              <IpdbOptionItem className="ipdb-option-item">
                <BinUpdateButton
                  type="primary"
                  data-testid="update-bin-settings-button"
                  onClick={() => onBinSettingChange(selectedIpdbFieldData)}>
                  Update Bin Settings
                </BinUpdateButton>
              </IpdbOptionItem>
              {binErrorMsg && <Alert message={binErrorMsg} type="error" />}
              <IpdbOptionItem className="ipdb-option-item">
                <ColorSelectorContainer>
                  <label>Colour</label>
                  <ColorWrapper>
                    <Switch
                      checked={reverseColor}
                      onChange={(val) => setReverseColor(val)}
                      checkedChildren={"Reverse"}
                      unCheckedChildren={"Reverse"}
                    />
                    <Select
                      value={ipdbColorPaletteName}
                      popupClassName="modal-select"
                      onChange={setIpdbColorPaletteName}>
                      {ipdbColorMaps.map((color) => {
                        return (
                          <Option key={color} value={color}>
                            {color}
                          </Option>
                        );
                      })}
                    </Select>
                  </ColorWrapper>
                </ColorSelectorContainer>
              </IpdbOptionItem>
            </div>
          );
        }}
      </BaseMenu>
    </>
  );
}

const BinContainer = styled.div`
  display: flex;
  gap: 5px;
  flex-direction: row;
`;

const BinInputLabel = styled.span`
  font-size: 1.2rem;
  color: #9b9b9b;
`;

const BinItem = styled.div`
  max-width: 76px;
`;
const ColorWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
`;

const IpdbOptionItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .ant-select {
    width: 240px;
  }
`;

export const LoadingContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
`;

const StyledLabel = styled.label`
  width: 240px;
`;

const SwitcherIcon = styled.span`
  transform: translateY(2px);
  color: rgba(var(--color-text-rgb), 0.3);
`;

const ColorSelectorContainer = styled.div`
  display: flex;
  gap: 5px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .ant-select {
    width: 154px;
  }

  .ant-switch {
    width: 80px;
  }
`;

const BinUpdateButton = styled(Button)`
  --ant-primary-color-hover: var(--color-primary-hover);
  grid-column: 1 / span 4;
  border-radius: 20px;
  width: 100%;
  font-weight: 500;
`;
