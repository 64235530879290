import { memo, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { Clear } from "@material-ui/icons";
import Lock from "@material-ui/icons/Lock";
import LockOpen from "@material-ui/icons/LockOpen";
import { updateHoverLegendItem } from "store/features";
import styled from "styled-components";

import { LegendItemModel } from "models/LegendItem";

import { Badge, BaseCheckboxInput, BaseTooltip } from "components/base";

import "./LegendItem.scss";

export interface LegendItemComponentModel {
  active?: boolean;
  allowChecked?: boolean;
  item: LegendItemModel;
  selected?: boolean;
  showOnlyTemplate?: boolean;
  showCounts?: boolean;
  template?: (item: LegendItemModel) => JSX.Element;
  onCheckChanged?: (checked, item) => void;
  onClick?: (event) => void;
  onClickLock?: (event) => void;
  onMouseEnter?: (item) => void;
  showGroupsNotInFilter?: boolean;
  // rest of props
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

function LegendItem({
  active = false,
  allowChecked = false,
  item,
  selected = false,
  showOnlyTemplate = false,
  showCounts = true,
  template = null,
  onCheckChanged,
  onClick = null,
  onClickLock = null,
  showGroupsNotInFilter = true,
  ...rest
}: LegendItemComponentModel) {
  const dispatch = useDispatch();
  const [isSelected, setSelected] = useState(selected);

  // sync props with local state
  useEffect(() => {
    setSelected(selected);
  }, [selected]);

  //Update the hovered legend item if the user remains on the item.
  const handleMouseEnter = useCallback(() => {
    dispatch(updateHoverLegendItem(item.title));
  }, [dispatch, item.title]);

  // Cancel the mouse enter event if it had not fired yet.
  const handleMouseLeave = useCallback(() => {
    dispatch(updateHoverLegendItem(""));
  }, []);

  return (
    <div
      className={`legend-item ${isSelected ? "selected" : ""} ${active ? "active" : ""}`}
      onClick={onClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      {...rest}>
      {template && template(item)}
      {allowChecked && (
        <BaseCheckboxInput
          value={item.isChecked}
          checkIcon={
            item.isNotEqual ? (
              <Clear
                className="icon check"
                style={{
                  backfaceVisibility: "hidden",
                  display: "block",
                  flexShrink: 0,
                  fill: "white",
                  height: "18",
                  width: "18"
                }}
              />
            ) : null
          }
          onChange={(val) => {
            onCheckChanged && onCheckChanged(val, item);
          }}
        />
      )}
      <BaseTooltip text="Lock Color">
        <span
          className={`color-rect ${item.isColorLocked ? "color-locked" : ""}`}
          style={{
            background:
              item.inFilter &&
              (item.count !== 0 || showGroupsNotInFilter) &&
              item.color !== null
                ? item.color
                : "",
            display:
              item.inFilter &&
              (item.count !== 0 || showGroupsNotInFilter) &&
              item.color !== null
                ? "inline-block"
                : "none"
          }}
          onClick={() => onClickLock(item)}>
          <span className="lock-icon">
            {item.isColorLocked ? <Lock /> : <LockOpen />}
          </span>
        </span>
      </BaseTooltip>
      {!showOnlyTemplate && (
        <TitleBadgeWrapper
          className={`${item.inFilter ? "" : "not-in-filter"}
          ${isSelected ? "selected" : ""}
          ${item.isChecked && !item.isNotEqual ? "is-checked" : ""}
          ${item.isNotEqual ? "is-not-equal" : ""}
        `}>
          <span className={`legend-item-title`} title={item.title}>
            {/* The map screenshot legend is consistent with the chart legends and the focus legend is different (shows count separately) */}
            {item.count != null && !item.isScreenShot
              ? item.value
              : showCounts
              ? item.text
              : item.value}
          </span>
          {item.count != null && showCounts && !item.isScreenShot && (
            <div className={`legend-item-count`}>
              <Badge>{item.count}</Badge>
            </div>
          )}
        </TitleBadgeWrapper>
      )}
    </div>
  );
}

const TitleBadgeWrapper = styled.div`
  min-width: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  &.not-in-filter {
    .legend-item-title {
      color: #9b9b9b;
    }
    .Badge {
      color: #bbb;
    }
  }
  &.is-checked {
    font-weight: 700;
  }
  &.selected {
    .legend-item-title {
      color: #1e2029;
    }
  }
  &.is-not-equal {
    .legend-item-title,
    .Badge {
      color: #ff2855;
    }
  }
`;

export default memo(LegendItem);
