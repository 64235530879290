import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Alert, TreeSelect } from "antd";
import { setCategoryList } from "store/features";
import { RootState } from "store/rootReducer";
import styled from "styled-components";

import { GetOrgLevelShapefilesInProject, GetShapefileBranch } from "api/project";

import { IGroupByListColumn } from "models";

import { useSelectedProject } from "components/project/projects/hooks";
import { useProjectShapefilesQuery } from "components/project/shapefiles/queries";

import { useSelectedCategory } from "./hooks";

export const ShapefilePrefix = "Shapefile";

function ShapefileCategory() {
  const dispatch = useDispatch();
  const categoryList = useSelector((state: RootState) => state.groupBy.categoryList);
  const { isShapefileCategory } = useSelectedCategory();
  const { selectedProject } = useSelectedProject();
  const [shapefiles, setShapefiles] = useState([]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const shapefilesDictRef = useRef<{ [shapefile: string]: any }>({});
  const [selectedShapefile, setSelectedShapefile] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string>();

  const shapefilesQuery = useProjectShapefilesQuery({
    projectId: selectedProject?.projectId
  });

  useEffect(() => {
    const fetchShapefiles = async () => {
      try {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const [projectNodes, orgNodes]: [any, any] = await Promise.all([
          new Promise((resolve, reject) => {
            GetShapefileBranch(selectedProject.projectId, resolve, reject);
          }),
          new Promise((resolve, reject) => {
            GetOrgLevelShapefilesInProject(selectedProject.projectId, resolve, reject);
          })
        ]);

        const parseShapefileNodes = (node, shapefilesDict) => {
          const n = {
            value: node.shapefileId ? node.shapefileId : node.shapefileNodeId,
            title: node.title,
            selectable: node.type === "shapefile",
            children:
              node.children?.map((ch) => parseShapefileNodes(ch, shapefilesDict)) ?? []
          };
          if (node.type === "shapefile") {
            shapefilesDict[node.shapefileId] = node;
          }
          return n;
        };

        setSelectedShapefile(undefined);
        // Clear out existing shapefiles
        shapefilesDictRef.current = {};

        const projectShapefileNodes = projectNodes.children
          .map((node) => parseShapefileNodes(node, shapefilesDictRef.current))
          // Remove empty folders
          .filter((node) => node.selectable || node.children.length > 0);

        const orgShapefileNodes = orgNodes.children
          .map((node) => parseShapefileNodes(node, shapefilesDictRef.current))
          // Remove empty folders
          .filter((node) => node.selectable || node.children.length > 0);

        const combinedShapefileNodes = [...projectShapefileNodes, ...orgShapefileNodes];

        // Filter nodes to only include those added to the currently selected project tree
        // const filteredShapefileNodes = combinedShapefileNodes.filter((node) =>
        //           selectedProject.tree.some((projectNode) => projectNode.key === node.value)
        // );

        setShapefiles(combinedShapefileNodes);
      } catch (error) {
        if (error.response?.data) {
          setErrorMessage(error.response?.data);
        } else {
          setErrorMessage("An error occurred while fetching shapefiles.");
        }
      }
    };

    fetchShapefiles();
  }, [selectedProject, shapefilesQuery?.data]);

  function onSelectShapefile(shapefileId: string) {
    if (!(shapefileId in shapefilesDictRef.current)) {
      return;
    }
    setSelectedShapefile(shapefileId);
    const shapefile = shapefilesDictRef.current[shapefileId];
    const columns = shapefile.properties.map((column) => {
      return {
        title: column,
        id: column,
        canBin: false,
        dataType: "Text",
        canNormalize: false,
        group: ShapefilePrefix,

        subgroup: shapefile.title,
        property: `${ShapefilePrefix}_${selectedProject.projectId}_${shapefileId}_${btoa(
          column
        )}`
      } as IGroupByListColumn;
    });

    const shapefileListIndex = categoryList.findIndex(
      (item) => item.name === ShapefilePrefix
    );

    if (shapefileListIndex < 0) {
      return;
    }
    const list = [...categoryList];
    list.splice(
      shapefileListIndex,
      1,
      Object.assign({}, categoryList[shapefileListIndex], { columns: columns })
    );
    //update the shapefile columns in store
    dispatch(setCategoryList(list));
  }

  if (!isShapefileCategory) {
    return null;
  }
  return (
    <Wrapper>
      <TreeSelect
        treeLine
        onSelect={onSelectShapefile}
        value={selectedShapefile}
        popupClassName="shapefile-tree-select"
        dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
        placeholder="Please select a Shapefile"
        treeData={shapefiles}
      />
      {errorMessage && <Alert type="error" message={errorMessage} />}
    </Wrapper>
  );
}

export default ShapefileCategory;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 5px;
  min-width: 100%;
  .ant-tree-select {
    width: 100%;
  }
`;
